/** @format */
import { decrypt } from "utils/secure";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = decrypt(window.localStorage.getItem("3MTAAT"));

    headers.set("Content-Type", "application/json");
    if (accessToken) {
      headers.set("Authorization", `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // window.localStorage.removeItem("3MTAAT");
    // window.localStorage.removeItem("3MTAUSER");
    // window.location.replace("/login");
  }
  return result;
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [API_TAGS.FETCH_WEBINARS, API_TAGS.FETCH_FAQS],
  endpoints: (builder) => ({
    getAllFellows: builder.query({
      query: () => `provider/myfellows`,
      providesTags: [API_TAGS.FETCH_PROVIDER_FELLOWS],
    }),
    getFellowProfile: builder.query({
      query: () => `account/profile`,
      providesTags: [API_TAGS.FETCH_FELLOW_INFO],
    }),
    getAdminForums: builder.query({
      query: () => `admin/forum/all`,
      providesTags: [API_TAGS.FETCH_ADMIN_FORUMS],
    }),
    getCourseModule: builder.query({
      query: () => `course/module`,
      providesTags: [API_TAGS.FETCH_COURSE_MODULES],
    }),
    getAdminMessages: builder.query({
      query: (roomId) => `admin/forum/messages/${roomId}`,
      providesTags: [API_TAGS.FETCH_ADMIN_MESSAGES],
    }),

    createAdminWebinar: builder.mutation({
      query: (body) => ({
        url: `webinar/admin/create`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_WEBINAR],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    getAdminWebinars: builder.query({
      query: () => `webinar/list`,
      providesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    updateAdminWebinar: builder.mutation({
      query: (body) => ({
        url: `webinar/admin/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_WEBINAR],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    deleteAdminWebinar: builder.mutation({
      query: (id) => ({
        url: `webinar/admin/detail/${id}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_WEBINAR],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),

    createWebinarEpisode: builder.mutation({
      query: (body) => ({
        url: `webinar/admin/create/episodes`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_EPISODE],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    getWebinarEpisodes: builder.query({
      query: (id) => `webinar/${id}`,
      providesTags: [API_TAGS.FETCH_EPISODES],
    }),
    updateWebinarEpisode: builder.mutation({
      query: (body) => ({
        url: `webinar/admin/${body?.id}/episodes`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_EPISODE],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    deleteWebinarEpisode: builder.mutation({
      query: (body) => ({
        url: `webinar/admin/${body?.episode}/episodes?webinar=${body?.webinar}`,
        method: "DELETE",
        body,
      }),
      providesTags: [API_TAGS.DELETE_EPISODE],
      invalidatesTags: [API_TAGS.FETCH_WEBINARS],
    }),
    filterFellows: builder.mutation({
      query: (body) => ({
        url: "provider/myfellows/filter",
        method: "POST",
        body,
      }),
    }),
    exportFellows: builder.mutation({
      query: (body) => ({
        url: "admin/export/fellows?",
        method: "POST",
        body,
      }),
    }),
    exportFellow: builder.query({
      query: (filter) => `admin/export/fellows${filter.length > 0 ? `?${filter}` : ""}`,
    }),
    exportProviders: builder.query({
      query: (filter) => `admin/export/providers${filter.length > 0 ? `?${filter}` : ""}`,
    }),
    updateFellowStatus: builder.mutation({
      query: (body) => ({
        url: `provider/myfellows/${body?.id}/update`,
        method: "PUT",
        body: {
          status: body?.status,
        },
      }),
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_FELLOWS],
    }),
    updateProviderProfile: builder.mutation({
      query: (body) => ({
        url: `account/profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.UPDATE_PROVIDER_PROFILE],
    }),

    getProviders: builder.query({
      query: (query) => `admin/providers${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_PROVIDERS],
    }),

    getCohorts: builder.query({
      query: (query) => `cohorts/all`,
      providesTags: [API_TAGS.GET_ALL_COHORTS]
    }),
    createCohort: builder.mutation({
      query: (body) => ({
        url: `cohorts/all`,
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_COHORTS],
    }),
    updateCohort: builder.mutation({
      query: (body) => ({
        url: `cohorts/detail/${body?.id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_COHORTS],
    }),
    getFellows: builder.query({
      query: (query) => `admin/fellows${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_FELLOWS],
    }),

    getAdminFAQTypes: builder.query({
      query: () => `faqs/types`,
      providesTags: [API_TAGS.FETCH_FAQ_TYPES],
    }),
    createAdminFAQ: builder.mutation({
      query: (body) => ({
        url: `faqs/admin/create`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_FAQ],
      invalidatesTags: [API_TAGS.FETCH_FAQS],
    }),
    getAdminFAQs: builder.query({
      query: () => `faqs/all`,
      providesTags: [API_TAGS.FETCH_FAQS],
    }),
    updateAdminFAQs: builder.mutation({
      query: (body) => ({
        url: `faqs/admin/detail/${body?.id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_FAQS],
      invalidatesTags: [API_TAGS.FETCH_FAQS],
    }),
    deleteAdminFAQs: builder.mutation({
      query: (id) => ({
        url: `faqs/admin/detail/${id}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_FAQS],
      invalidatesTags: [API_TAGS.FETCH_FAQS],
    }),
    sendEmails: builder.mutation({
      query: (body) => ({
        url: `emails/send`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.SEND_EMAIL],
      invalidatesTags: [API_TAGS.SEND_EMAIL],
    }),
    bulkAssign: builder.mutation({
      query: (body) => ({
        url: `admin/bulk/assign/fellows/to/providers`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.BULK_ASSIGN_STATUS],
      invalidatesTags: [API_TAGS.GET_ALL_FELLOWS],
    }),
    bulkAssignCohort: builder.mutation({
      query: (body) => ({
        url: `cohorts/bulk/assign/fellows`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.BULK_ASSIGN_COHORT],
      invalidatesTags: [API_TAGS.GET_ALL_FELLOWS],
    }),
    bulkUpdateStatus: builder.mutation({
      query: (body) => ({
        url: `admin/bulk/change/fellows/status`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.BULK_UPDATE_STATUS],
      invalidatesTags: [API_TAGS.GET_ALL_FELLOWS],
    }),
    updateFellowProfile: builder.mutation({
      query: (body) => ({
        url: `admin/fellows/${body?.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [API_TAGS.FETCH_FELLOW_INFO],
    }),
    UpdateProviderDetails: builder.mutation({
      query: (body) => ({
        url: `admin/providers/${body?.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [API_TAGS.UPDATE_PROVIDER_PROFILE],
    }),
    bulkUpdateProviderStatus: builder.mutation({
      query: (body) => ({
        url: `admin/bulk/change/providers/status`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.BULK_PROVIDER_UPDATE_STATUS],
      invalidatesTags: [API_TAGS.GET_ALL_PROVIDERS],
    }),
    getLearningTrackerHistory: builder.query({
      query: (query) => ({
        url: `admin/all/uploaded/learning/trackers`,
      })
    }),
    getUsers: builder.query({
      query: (query) => `admin/users${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_USERS],
    }),
    getPendingUsers: builder.query({
      query: (query) => `admin/pending/invites${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_PENDING_USERS],
    }),
    getAllRoles: builder.query({
      query: (query) => `roles/all`,
      providesTags: [API_TAGS.GET_ALL_ROLES],
    }),
    getAllPermissions: builder.query({
      query: () => `roles/all/permissions`,
      providesTags: [API_TAGS.GET_ALL_PERMISSIONS],
    }),
    updateAdminUser: builder.mutation({
      query: (body) => ({
        url: `admin/users/${body?.userId}`,
        method: "PATCH",
        body:
          body.source === "update_status"
            ? {
                active: body?.active,
              }
            : {
                role_id: body?.role,
              },
      }),
      providesTags: [API_TAGS.UPDATE_ADMIN_USER],
      invalidatesTags: [API_TAGS.GET_ALL_USERS],
    }),
    createAdminRole: builder.mutation({
      query: (body) => ({
        url: `roles/all`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_ROLE],
      invalidatesTags: [API_TAGS.GET_ALL_ROLES],
    }),
    updateAdminRole: builder.mutation({
      query: (body) => ({
        url: `roles/detail/${body.roleId}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_ROLE],
      invalidatesTags: [API_TAGS.GET_ALL_ROLES],
    }),
    deleteAdminRole: builder.mutation({
      query: (body) => ({
        url: `roles/detail/${body}`,
        method: "DELETE",
        body,
      }),
      providesTags: [API_TAGS.DELETE_ROLE],
      invalidatesTags: [API_TAGS.GET_ALL_ROLES],
    }),
    inviteUser: builder.mutation({
      query: (body) => ({
        url: `admin/auth/send/invite`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.SEND_USER_INVITE],
      invalidatesTags: [API_TAGS.GET_ALL_USERS],
    }),
    resendInvite: builder.mutation({
      query: (body) => ({
        url: `admin/auth/resend/invite`,
        method: "POST",
        body,
      }),
    }),
    updateUserEmail: builder.mutation({
      query: (body) => ({
        url: "admin/email/update",
        method: "POST",
        body
      })
    }),
    getProviderBranches: builder.query({
      query: (body) => ({
        url: `admin/provider/branches?provider_id=${body.id}`,
      })
    }),
    getAllAnalysis: builder.query({
      query: (query) => ({
        url: `admin/analytics/all${query.length > 0 ? `?${query}` : ""}`
      })
    }),
    getFellowAnalysis: builder.query({
      query: (query) => ({
        url: `admin/analytics/fellows${query.length > 0 ? `?${query}` : ""}`
      })
    }),
    getProviderAnalysis: builder.query({
      query: (query) => ({
        url: `admin/analytics/providers${query.length > 0 ? `?${query}` : ""}`
      })
    }),
    getSingleProviderAnalysis: builder.query({
      query: (query) => ({
        url: `admin/analytics/single-provider${query.length > 0 ? `?${query}` : ""}`
      })
    }),
    getAttendanceAnalysis: builder.query({
      query: (query) => ({
        url: `admin/analytics/attendance${query.length > 0 ? `?${query}` : ""}`
      })
    }),
    getAdminSurveys: builder.query({
      query: (query) => `admin/surveys${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_SURVEYS],
    }),
    createAdminSurvey: builder.mutation({
      query: (body) => ({
        url: "admin/surveys/",
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_SURVEYS],
    }),
    deleteAdminSurvey: builder.mutation({
      query: (id) => ({
        url: `admin/surveys/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [API_TAGS.GET_ALL_SURVEYS],
    }),
    updateAdminSurvey: builder.mutation({
      query: (body) => ({
        url: `admin/surveys/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [API_TAGS.GET_ALL_SURVEYS],
    }),
    getFellowCertificates: builder.query({
      query: (query) => `admin/certificates${query.length>0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_ALL_FELLOW_ONLINE_CERTIFICATES]
    }),
    updateFellowCertificates: builder.mutation({
      query: (body) => ({
        url: `admin/certificates/${body.id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_FELLOW_ONLINE_CERTIFICATES]
    }),
    matchFellowsToProvider: builder.mutation({
      query: (body) => ({
        url: `admin/match/fellows/to/provider`,
        method: "POST",
        body
      })
    }),
    matchProviderBranchToCourse: builder.mutation({
      query: (body) => ({
        url: `admin/match/course/to/provider/branch`,
        method: "POST",
        body
      })
    }),
    getMatchedProviderBranches: builder.query({
      query: (query) => ({
        url: `admin/matched/branches/to/courses/and/cohort${query.length>0 ? `?${query}` : ""}`
      })
    }),
    getMatchedProviderBranchesReports: builder.query({
      query: (query) => ({
        url: `admin/matched/branches/to/courses/and/cohort/report`
      })
    }),
    createCarouselBroadcast:  builder.mutation({
      query: (body) => ({
        url: "broadcasts/carousels/admin/",
        method: "POST",
        body
      }),
      providesTags: [API_TAGS.CREATE_CAROUSEL_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_CAROUSEL_BROADCASTS]
    }),
    getCarouselBroadcast:  builder.query({
      query: (query) => ({
        url: `broadcasts/carousels/admin/${query.length > 0 ? `?${query}` : ""}`,
      }),
      providesTags: [API_TAGS.FETCH_CAROUSEL_BROADCASTS],

    }),
    deleteBroadcastCarousel: builder.mutation({
      query: (ID) => ({
        url: `broadcasts/carousels/admin/${ID}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_CAROUSEL_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_CAROUSEL_BROADCASTS],
    }),
    updateBroadcastCarousel: builder.mutation({
      query: (body) => ({
        url: `broadcasts/carousels/admin/${body.id}`,
        method: "PATCH",
        body
      }),
      providesTags: [API_TAGS.UPDATE_CAROUSEL_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_CAROUSEL_BROADCASTS],
    }),
    getDeeptechs: builder.query({
      query: (query) => `cohorts/deeptech/all`,
      providesTags: [API_TAGS.GET_ALL_DEEPTECHS]
    }),
    createDeeptech: builder.mutation({
      query: (body) => ({
        url: `cohorts/deeptech/all`,
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_DEEPTECHS],
    }),
    updateDeeptech: builder.mutation({
      query: (body) => ({
        url: `cohorts/deeptech/detail/${body?.id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_DEEPTECHS],
    }),
    bulkAssignDeeptech: builder.mutation({
      query: (body) => ({
        url: `cohorts/deeptech/bulk/assign/fellows`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.BULK_ASSIGN_COHORT],
      invalidatesTags: [API_TAGS.GET_ALL_FELLOWS],
    }),

    // getProviderBranches: ilder.query({
    //   query: (body) => ({
    //     url: `admin/admin/community/online/course `,
    //   }),
    //   // providesTags: [API_TAGS.GET_COMMUNITY +RESOURCES], 
    //   // invalidatesTags: [API_TAGS.GET_ALL_USERS],
    // })
  }),
});

export const {
  useGetAllFellowsQuery,
  useGetFellowProfileQuery,
  useGetAdminForumsQuery,
  useGetAdminMessagesQuery,
  useGetCourseModuleQuery,
  useFilterFellowsMutation,
  useExportFellowsMutation,
  useExportFellowQuery,
  useExportProvidersQuery,
  useUpdateFellowStatusMutation,
  useUpdateProviderProfileMutation,
  useCreateAdminWebinarMutation,
  useGetAdminWebinarsQuery,
  useUpdateAdminWebinarMutation,
  useDeleteAdminWebinarMutation,
  useCreateWebinarEpisodeMutation,
  useGetWebinarEpisodesQuery,
  useUpdateWebinarEpisodeMutation,
  useDeleteWebinarEpisodeMutation,
  useGetProvidersQuery,
  useGetFellowsQuery,
  useGetAdminFAQTypesQuery,
  useGetAdminFAQsQuery,
  useCreateAdminFAQMutation,
  useUpdateAdminFAQsMutation,
  useDeleteAdminFAQsMutation,
  useSendEmailsMutation,
  useBulkAssignMutation,
  useBulkAssignCohortMutation,
  useBulkUpdateStatusMutation,

  useGetCohortsQuery,
  useCreateCohortMutation,
  useUpdateCohortMutation,

  useGetLearningTrackerHistoryQuery,

  useUpdateFellowProfileMutation,
  useUpdateProviderDetailsMutation,
  useBulkUpdateProviderStatusMutation,
  useGetUsersQuery,
  useGetAllRolesQuery,
  useGetAllPermissionsQuery,
  useUpdateAdminUserMutation,
  useCreateAdminRoleMutation,
  useUpdateAdminRoleMutation,
  useDeleteAdminRoleMutation,
  useInviteUserMutation,
  useGetPendingUsersQuery,
  useResendInviteMutation,
  useUpdateUserEmailMutation,
  useGetProviderBranchesQuery,
  useGetAllAnalysisQuery,
  useGetFellowAnalysisQuery,
  useGetProviderAnalysisQuery,
  useGetSingleProviderAnalysisQuery,
  useLazyGetSingleProviderAnalysisQuery,
  useGetAttendanceAnalysisQuery,

  useGetAdminSurveysQuery,
  useCreateAdminSurveyMutation,
  useDeleteAdminSurveyMutation,
  useUpdateAdminSurveyMutation,

  useGetFellowCertificatesQuery,
  useUpdateFellowCertificatesMutation,

  useMatchFellowsToProviderMutation,
  useMatchProviderBranchToCourseMutation,
  useGetMatchedProviderBranchesQuery,
  useGetMatchedProviderBranchesReportsQuery,

  useCreateCarouselBroadcastMutation,
  useGetCarouselBroadcastQuery,
  useDeleteBroadcastCarouselMutation,
  useUpdateBroadcastCarouselMutation,

  useGetDeeptechsQuery,
  useCreateDeeptechMutation,
  useUpdateDeeptechMutation,
  useBulkAssignDeeptechMutation

} = adminApi;
