/** @format */

import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { useBulkAssignDeeptechMutation, useGetDeeptechsQuery } from "store/services/adminAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";
import { Style } from "utils/select";


export const DeeptechModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
  setSelectedFellowIds,
}) => {
  const [selectedDeeptechs, setDeeptechs] = useState(null);
  const [bulkAssignDeeptech, { isLoading }] = useBulkAssignDeeptechMutation();
  const { data } = useGetDeeptechsQuery("");

  const handleSubmit = async () => {
    await bulkAssignDeeptech({
      deeptech_id: selectedDeeptechs?.value,
      fellows: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedIds([]);
        setSelectedFellowIds([]);
        setAction("");
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const DeeptechOptions =
    data?.data?.deeptechs?.length > 0
      ? data?.data?.deeptechs?.map((deeptech) => ({
          value: deeptech?.id,
          label: `Deeptech ${deeptech?.rank}`,
        }))
      : [];

  return (
    <Modal
      show={action === "assign_deeptech" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Advance Fellow(s) to Deeptech</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
              <div className="card-header border-0 pb-0">
                <h5>Select Deeptech</h5>
              </div>
              <div className="card-body pt-0">
                {/* <p>selectedCohorts:: {JSON.stringify(selectedCohorts)}</p> */}
                <div className="description">
                  <Select
                    value={selectedDeeptechs}
                    onChange={setDeeptechs}
                    options={DeeptechOptions}
                    styles={Style}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={
                selectedDeeptechs && selectedIds?.length > 0 ? false : true
              }
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Assign`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
