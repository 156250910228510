import React, { useState } from "react";
import AdminLayout from "views/admin/Admin";
import { useGetDeeptechsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import DeeptechTable from "views/admin/table/Deeptech/DeeptechTable";
import usePermissionCheck from "hooks/useCheck";
import { Page } from "utils/constant.data";



const AdminDeeptech = () => {
  usePermissionCheck(Page.COHORTS)
  const [filter, setFilter] = useState("page=1");

  const {
    data,
    isLoading,
  } = useGetDeeptechsQuery();



  return (
    <AdminLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
            <div>
          <DeeptechTable
            deeptechs={data?.data?.deeptechs}
            isLoading={isLoading}
            metadata={data?.data?.metadata}
            filter ={filter}
            setFilter={setFilter}
          />
            </div>

        )}
      </div>
    </AdminLayout>
  );
};
export default AdminDeeptech;
